.brand {
  .title {
    //width: 255px;
    //height: 233px;
  }

  .image {
    height: 140px;
    overflow: hidden;

    img {
      width: 100%;
      height: 140px;
    }
  }

  .text {
    font-size: 18px;
    line-height: 21px;
    padding: 8px;
    height: 58px;
  }
}

.home-brand {
  img {
    @include media-breakpoint-down(md) {
      padding: 0.5rem;
    }
    padding: 3rem;
  }
}