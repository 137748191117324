.banner {
  max-height: 100vh;
  overflow: hidden;
  position: relative;

  img {
    width: 100%;
    @include media-breakpoint-up(md) {
      margin-top: -156px;
    }
  }

  .main-text {
    font-style: normal;
    font-weight: 500;
    font-size: 5vw;
    //line-height: 84px;
    color: white;
  }

  .sub-text {
    font-style: normal;
    font-weight: 400;
    font-size: 3vw;
    //line-height: 56px;
    color: #fff;
    width: 70%;
  }

  .text {
    top: 59%;
    @include media-breakpoint-down(md) {
      top: 46%
    }
    left: 41%;
    width: 71%;
    transform: translate(-50%, -50%);
    position: absolute;
  }
}