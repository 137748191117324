$bg-general: #FFF;
$bg-content: #F6F6F6;
$bg-main: #E5E5E5;

$bg_text: #E5E5E5;

$blue_dark: #003461;
$blue_middle: #08548A;
$blue_light: #01C1F3;

$link: #08548A;
$link-hover: #01C1F3;

$headline: #003461;
$paragraph: #08548A;


$body: "IBM Plex Sans, sans-serif";
$heading: "IBM Plex Sans, sans-serif";
