.product {
  p {
    margin-bottom: 5px;
  }
  .product-image {
    height: 300px;

    img {
      max-width: 100%;
      max-height: 300px;
    }
  }
  .table {
    @include media-breakpoint-down(md) {
      overflow: scroll;
    }
  }

  a {
    color: $blue_light;
    font-style: italic;
    font-weight: 400;
  }
}