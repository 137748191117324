@import "~bootstrap/scss/bootstrap";

@import "custom";
@import "variable";
@import "headline";
@import "navbar";
@import "banner";
@import "section";
@import "category";
@import "product";
@import "brand";
@import "works";

.direction-column-center {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}

.display-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.display-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 35px;
  line-height: 41px;
  color: #FFFFFF;
}

h3 {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  text-transform: uppercase;
  color: #FFFFFF;
}

h4 {
  font-style: normal;
  font-weight: normal;
  font-size: 39px;
  //line-height: 46px;
  color: $blue_dark;
}

h5 {
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 20px;
  color: $blue_dark;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 179.69%;
  color: $blue_middle;
}

a, .navbar-light .navbar-nav .nav-link {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  color: $blue_middle;
  text-decoration: none;
}

hr {
  border-color: $blue_middle;
  margin-bottom: 0;
  margin-top: 0;
}

.general-shadow {
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.3);

  &:hover {
    box-shadow: 0 0 9px #08548A;
  }
}

.thumbNailView img {
  height: 60px;
  width: 60px;
  border-radius: 50%
}

.image-fullscreen {
  .image-field {
    img {
      height: 100px;
    }
  }

  h3 {
    color: $blue_dark;
  }

  ul.images {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-gap: 15px;
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul.images > li {
    position: relative;
    padding-top: 66%;
  }

  ul.images > li img {
    //position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    max-height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  .fullscreen-image {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.content {
  min-height: calc(100vh - 160px);
}

.promoute {
  height: 100px;
  @include media-breakpoint-down(md) {
    height: 50px;
    width: 80%;
  }


  h4 {
    font-size: 4vw;
    color: #fff;
    text-transform: uppercase;
  }
}