.works-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  a {
    text-decoration: none;
  }
}

.work-box-container {
  width: 33.3%;
  //height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.work-box {
  padding: 20px;
  height: 360px;
  display: flex;
  flex-direction: column;

  color: $blue_light;
  border: 1px solid $blue_light;
  box-sizing: border-box;
  border-radius: 4px;

  .work-image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 240px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  &:hover {
    color: $blue_dark;
    border-color: $blue_middle;
  }

  h3 {
    color: $blue_dark
  }
}