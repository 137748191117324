.category {
  .title {
    img {
      height: 138px;
    }

    .name {
      height: 50px;
      font-size: 14px;
    }
  }

  p{
    line-height: 16px;
    margin: 0.25rem 0;
  }
}