.navbar {
  height: 80px;

  .logo {
    height: 60px;
  }

  .drop-down-menu {
    @include media-breakpoint-down(md) {
      z-index: 1;
      margin: 0px -30px;
      padding: 30px;
    }
  }
}