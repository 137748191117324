.section {
  .tile {
    height: 100%;
    //margin-bottom: 30px;

    .link{
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 179.69%;
      text-align: center;
      margin: 25px;
      padding: 0 10px;
      text-decoration: none;
      @include media-breakpoint-up(md) {
        margin: 0 10px 0 0;
      }

      color: $blue_light;
      border: 1px solid $blue_light;
      box-sizing: border-box;
      border-radius: 4px;

      &:hover {
        color: $blue_dark;
        border-color: $blue_middle;
      }
    }

    .brand-image{
      width: 100%;
      height: 40px;
      img {
        height: 100%;
      }
    }

    .big-image {
      height: 255px;
      img {
        height: 100%;
      }
    }
  }
}